<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <form class="" @submit.prevent="">
      <div>
        <base-input :label="`${$t(`COMMON.USERS`)} (*)`">
          <users-selector
            :filterable="true"
            :showAll="false"
            :multiple="true"
            :notLinkedToLocationFilter="location?.id"
            :filterOrganization="location?.organization?.id"
            @usersChanged="
              (users) => {
                selectedUsers = users;
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.users" />
      </div>
    </form>

    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        :disabled="loading"
        @click="handleSubmit"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("USERS.ADD_USERS") }}
      </button>

      <button
        type="button"
        @click="closeFormModal"
        :disabled="loading"
        class="btn btn-link ml-auto"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Tooltip,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";

import UsersSelector from "@/components/UsersSelector.vue";
import { cloneDeep } from "lodash";

export default {
  name: "location-users-form",

  components: {
    ValidationError,
    UsersSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["location", "showModal", "get"],

  data() {
    return {
      loading: false,
      formErrors: null,
      selectedUsers: [],
    };
  },

  computed: {},

  methods: {
    closeFormModal() {
      this.$emit("onChangeModalState");
    },

    async handleSubmit() {
      const users = this.selectedUsers.map((item) => {
        return item.id;
      });
      this.loading = true;
      try {
        await this.$store.dispatch("locations/attachUsers", {
          id: this.location?.id,
          users: users,
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("LOCATIONS.LOCATIONS_UPDATED"),
        });
        this.get();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    location(location) {
      this.resetApiValidation();
    },
  },
};
</script>
