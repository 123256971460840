<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!location">
      <span class="loader"></span>
    </span>
    <location-form
      v-if="location"
      :loading="loading"
      :locationData="location"
      :formErrors="formErrors"
      @locationSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import LocationForm from "../partials/LocationForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: { LocationForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    locationId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      location: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("locations/get", this.locationId);
        const location = this.$store.getters["locations/location"];
        this.location = {
          ...location,
          manager: location?.manager?.id
            ? location?.manager
            : {
                type: "users",
                id: null,
              },
        };
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(location) {
      this.loading = true;

      const locationData = cloneDeep(location);

      try {
        await this.$store.dispatch("locations/update", locationData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("LOCATIONS.LOCATIONS_UPDATED"),
        });
        let location = this.$store.getters["locations/location"];
        location = {
          ...location,
          manager: location?.manager?.id
            ? location?.manager
            : {
                type: "users",
                id: null,
              },
        };
        this.$emit("onViewLocation", location, true);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error?.response?.status === 403) {
          await this.showRequestError(error);
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.formErrors = error.response.data.errors;
        }
      }
    },
  },
};
</script>
